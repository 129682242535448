import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  Button,
  MenuItem,
  Switch,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Menu,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import CustomTextField from "./CustomTextField";
import BarcodeScanner from "./BarcodeScanner";
import debounce from "lodash/debounce";
import { format } from "date-fns";
import "./App.css";

const App = () => {
  const [lotNumber, setLotNumber] = useState("");
  const [locationInput, setLocationInput] = useState("");
  const [locations, setLocations] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [autoSubmitEnabled, setAutoSubmitEnabled] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [scannerVisible, setScannerVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("Sunrise");
  const [apiKey, setApiKey] = useState(null);

  const USE_HARDCODED_API_KEY = true;
  const HARDCODED_API_KEY = process.env.REACT_APP_API_KEY;

  const fetchApiKey = useCallback(async () => {
    const token =
      "6811c1c0fcc614b857f6072c2de5b3445069a60b76248b26032b715ca3fe571e";
    try {
      const response = await fetch(`/get_api_key.php?token=${token}`);
      const data = await response.json();
      if (data.api_key) {
        setApiKey(data.api_key);
      } else {
        console.error("API key not found");
      }
    } catch (error) {
      console.error("Error fetching API key:", error);
    }
  }, []);

  useEffect(() => {
    if (USE_HARDCODED_API_KEY) {
      setApiKey(HARDCODED_API_KEY);
    } else {
      fetchApiKey();
    }
  }, [fetchApiKey, HARDCODED_API_KEY, USE_HARDCODED_API_KEY]);

  const [activeField, setActiveField] = useState("");

  const locationInputRef = useRef(null);
  const lotNumberInputRef = useRef(null);

  const fetchLocations = useCallback(async () => {
    if (!apiKey) {
      console.error("API key not available");
      return;
    }
    const tableId =
      selectedLocation === "Sunrise"
        ? "tblNeDJuQ1p4iCcgO"
        : "tblvw8omvmyNOUNEi";
    const locations = [];
    let offset = null;

    try {
      do {
        const response = await axios.get(
          `https://api.airtable.com/v0/appWcj2G0jFNtcq8t/${tableId}`,
          {
            headers: {
              Authorization: `Bearer ${apiKey}`,
            },
            params: {
              offset,
            },
          }
        );
        locations.push(
          ...response.data.records.map((record) => record.fields.Name)
        );
        offset = response.data.offset;
      } while (offset);

      setLocations(locations);
      console.log("Fetched locations:", locations);
      localStorage.setItem("cachedLocations", JSON.stringify(locations));
      toast.success("Locations refreshed successfully");
    } catch (error) {
      console.error("Failed to fetch locations:", error);
      toast.error("Failed to refresh locations");
    }
  }, [apiKey, selectedLocation]);

  const fetchUsers = useCallback(async () => {
    if (!apiKey) {
      console.error("API key not available");
      return;
    }
    try {
      const response = await axios.get(
        `https://api.airtable.com/v0/appVRMZWM0I4rPxni/tbl9LGyPSjQmUhJs5?view=viwjtbunEvYRoyxLX`,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      setUsers(response.data.records);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  }, [apiKey]);

  useEffect(() => {
    if (apiKey) {
      fetchLocations();
      fetchUsers();
    }
  }, [apiKey, fetchLocations, fetchUsers, selectedLocation]);

  useEffect(() => {
    const savedUser = localStorage.getItem("selectedUser");
    if (savedUser) {
      const user = JSON.parse(savedUser);
      if (user && user.id && users.find((u) => u.id === user.id)) {
        setSelectedUser(user.id);
        setWelcomeMessage(`Welcome ${user.fields["First Name"]}`);
      }
    }

    const cachedLocations = localStorage.getItem("cachedLocations");
    if (cachedLocations) {
      setLocations(JSON.parse(cachedLocations));
    }

    if (lotNumberInputRef.current) {
      lotNumberInputRef.current.focus();
    }
  }, [users]);

  useEffect(() => {
    if (selectedUser === "" && users.length > 0) {
      setSelectedUser(users[0].id);
      setWelcomeMessage(`Welcome ${users[0].fields["First Name"]}`);
    }
  }, [users, selectedUser]);

  const validateLotNumber = useCallback((lotNumber) => {
    return lotNumber.trim().length === 6 && !isNaN(lotNumber);
  }, []);

  const validateLocation = useCallback(
    (location) => {
      return locations.includes(location.trim());
    },
    [locations]
  );

  const checkLocationInCache = useCallback(() => {
    const trimmedLocation = locationInput.trim();
    if (trimmedLocation && locations.includes(trimmedLocation)) {
      return true;
    } else {
      toast.error("Location not found");
      return false;
    }
  }, [locationInput, locations]);

  const handleLotNumberChange = (e) => {
    setLotNumber(e.target.value);
    if (validateLotNumber(e.target.value)) {
      locationInputRef.current.focus();
    }
  };

  const handleLocationInputChange = (e) => {
    setLocationInput(e.target.value.toUpperCase());
  };

  const handleUserChange = (userId) => {
    const user = users.find((user) => user.id === userId);
    setSelectedUser(userId);
    setWelcomeMessage(`Welcome ${user.fields["First Name"]}`);
    localStorage.setItem("selectedUser", JSON.stringify(user));
  };

  const findRecordIdByLotNumber = useCallback(
    async (lotNumber) => {
      try {
        const url = `https://api.airtable.com/v0/appWcj2G0jFNtcq8t/tblQnDT3hR0PxUHfu?filterByFormula={Lot Number}='${lotNumber}'`;

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });

        return response.data.records.length > 0
          ? response.data.records[0].id
          : null;
      } catch (error) {
        console.error("Failed to find record:", error);
        return null;
      }
    },
    [apiKey]
  );

  const updateRecord = useCallback(
    async (recordId, location, scannedBy) => {
      try {
        const response = await axios.patch(
          `https://api.airtable.com/v0/appWcj2G0jFNtcq8t/tblQnDT3hR0PxUHfu/${recordId}`,
          {
            fields: {
              "Scan Location": location,
              "Scanned By": scannedBy,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${apiKey}`,
              "Content-Type": "application/json",
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Failed to update record:", error);
        throw error;
      }
    },
    [apiKey]
  );

  const logEntry = useCallback(
    async (lotNumber, location, scannedBy, success) => {
      try {
        const response = await axios.post(
          `https://api.airtable.com/v0/appWcj2G0jFNtcq8t/tblYIoRk8UYzKPUS6`,
          {
            fields: {
              "Lot Number": lotNumber,
              Location: location,
              "Scanned By": scannedBy,
              Successful: success,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${apiKey}`,
              "Content-Type": "application/json",
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Failed to log entry:", error);
        throw error;
      }
    },
    [apiKey]
  );

  const handleSubmit = useCallback(async () => {
    if (!validateLotNumber(lotNumber)) {
      toast.error("Invalid lot number");
      return;
    }
    if (!selectedUser) {
      toast.error("Please select a user");
      return;
    }
    if (!checkLocationInCache()) {
      return;
    }

    const currentLotNumber = lotNumber;
    const currentLocationInput = locationInput;
    const currentSelectedUser = selectedUser;

    setTimeout(async () => {
      setLotNumber("");
      setLocationInput("");
      if (lotNumberInputRef.current) {
        lotNumberInputRef.current.focus();
      }
      const user = users.find((user) => user.id === currentSelectedUser);
      const timestamp = format(new Date(), "MM/dd/yy h:mm a");
      const scannedBy = `${user.fields["First Name"]} (${timestamp})`;
      try {
        const recordId = await findRecordIdByLotNumber(
          parseInt(currentLotNumber)
        );
        if (!recordId) {
          toast.error("Record not found");
          await logEntry(currentLotNumber, currentLocationInput, "N/A", false);
          return;
        }
        await updateRecord(recordId, currentLocationInput, scannedBy);
        await logEntry(
          currentLotNumber,
          currentLocationInput,
          scannedBy,
          "Success"
        );
        toast.success(`${currentLotNumber} saved to ${currentLocationInput}`);
      } catch (error) {
        await logEntry(
          currentLotNumber,
          currentLocationInput,
          scannedBy,
          "Fail"
        );
      }
    }, 100);
  }, [
    lotNumber,
    locationInput,
    selectedUser,
    validateLotNumber,
    checkLocationInCache,
    users,
    findRecordIdByLotNumber,
    updateRecord,
    logEntry,
  ]);

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  useEffect(() => {
    if (
      autoSubmitEnabled &&
      validateLotNumber(lotNumber) &&
      validateLocation(locationInput)
    ) {
      handleSubmit();
    }
  }, [
    lotNumber,
    locationInput,
    autoSubmitEnabled,
    handleSubmit,
    validateLotNumber,
    validateLocation,
    selectedUser,
  ]);

  const handleCameraClick = (field) => {
    console.log("Setting active field: ", field);
    setActiveField(field);
    setScannerVisible(true);
  };

  const debouncedHandleBarcodeDetected = useMemo(
    () =>
      debounce((barcode) => {
        console.log("Barcode detected: ", barcode);
        setScannerVisible(false);
        setActiveField((prevActiveField) => {
          console.log("Previous active field:", prevActiveField);
          if (prevActiveField === "lotNumber") {
            console.log("Updating lot number");
            setLotNumber(barcode);
          } else if (prevActiveField === "location") {
            console.log("Updating location");
            setLocationInput(barcode);
          }
          return prevActiveField;
        });
      }, 300),
    []
  );

  return (
    <div className="app-container">
      <Toaster position="top-center" />
      <div className="header">
        <div>
          <h2>{welcomeMessage}</h2>
          <p className="location-text">{selectedLocation}</p>
        </div>
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>
      </div>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem>
          <FormControl fullWidth margin="normal">
            <InputLabel id="user-select-label">Select User</InputLabel>
            <Select
              labelId="user-select-label"
              value={selectedUser}
              onChange={(e) => handleUserChange(e.target.value)}
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.fields["Full Name"]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MenuItem>
        <MenuItem>
          <FormControl fullWidth margin="normal">
            <InputLabel id="location-select-label">Select Location</InputLabel>
            <Select
              labelId="location-select-label"
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
            >
              <MenuItem value="Sunrise">Sunrise</MenuItem>
              <MenuItem value="Maule">Maule</MenuItem>
            </Select>
          </FormControl>
        </MenuItem>
        <MenuItem
          onClick={() => {
            fetchLocations();
            handleMenuClose();
          }}
        >
          Refresh Locations
        </MenuItem>
      </Menu>
      <CustomTextField
        className="textfield"
        label="Lot Number"
        value={lotNumber}
        onChange={handleLotNumberChange}
        fullWidth
        inputRef={lotNumberInputRef}
        onCameraClick={() => handleCameraClick("lotNumber")}
      />
      <CustomTextField
        className="textfield"
        label="Location"
        value={locationInput}
        onChange={handleLocationInputChange}
        fullWidth
        inputRef={locationInputRef}
        onCameraClick={() => handleCameraClick("location")}
      />
      <Button
        className="submit-button"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={
          !validateLotNumber(lotNumber) || !validateLocation(locationInput)
        }
      >
        Submit
      </Button>

      <div className="switch-container">
        Auto-Submit
        <Switch
          checked={autoSubmitEnabled}
          onChange={() => setAutoSubmitEnabled(!autoSubmitEnabled)}
          name="autoSubmit"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      </div>
      {scannerVisible && (
        <BarcodeScanner
          onDetected={debouncedHandleBarcodeDetected}
          onClose={() => setScannerVisible(false)}
        />
      )}
    </div>
  );
};

export default App;
