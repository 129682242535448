import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

const CustomTextField = ({
  value,
  onChange,
  label,
  inputRef,
  className,
  onCameraClick,
  ...props
}) => {
  const handleClear = () => {
    onChange({ target: { value: "" } });
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <TextField
      {...props}
      label={label}
      value={value}
      onChange={onChange}
      inputRef={inputRef}
      className={className}
      InputProps={{
        endAdornment: (
          <>
            {value && (
              <InputAdornment position="end">
                <IconButton onClick={handleClear} edge="end">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )}
            {!value && (
              <InputAdornment position="end">
                <IconButton onClick={onCameraClick} edge="end">
                  <CameraAltIcon />
                </IconButton>
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  );
};

export default CustomTextField;
